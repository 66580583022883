<template>
  <div slot="tool" class="float-right">
    <p-button type="danger" link v-on:click="resetToDefautSettings">
      <i slot="label" class="fa fa-undo"></i>
      {{ $t('resettodefaulttxt') }}
    </p-button>
    <p-button type="success" v-on:click="submit">
      <i slot="label" class="fa fa-floppy-o"></i>
      {{ $t('savechanges') }}
    </p-button>
    <p-button type="default" v-on:click="prevPage">
      <i slot="label" class="fa fa-remove"></i>
      {{ $t('cancel') }}
    </p-button>
    <p-button v-if="isAdmin" type="warning" v-on:click="toggleExpanded">
      <i slot="label" class="fa fa-warning"></i>
      Assets
    </p-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

const userInfo = JSON.parse(localStorage.getItem('user-info'));

export default {
  computed: {
    ...mapState('strategy_params', ['accountInfo']),
    ...mapGetters('strategy_params', ['isValidSettings']),
    isAdmin() {
      return userInfo && userInfo.roles && userInfo.roles.includes('admin');
    }
  },
  methods: {
    ...mapActions('strategy_params', ['toggleExpanded','openConfirmationModal', 'resetToDefautSettings']),
    prevPage() {
      if (userInfo && userInfo.roles && userInfo.roles.includes('admin')) {
        this.$router.push('/admin_accounts/' + this.accountInfo.userid);
      } else {
        this.$router.push('/accounts/main');

      }
    },
    submit() {
      if(!this.isValidSettings) {
        this.$toast.warning('Params missing value');
        return;
      }
      this.openConfirmationModal();
    },
  }
}
</script>

<style scoped>

</style>
