<template>
  <strategy-parameter-block
    icon="fa fa-flag-checkered"
    header-color="hg-warning"
    :title="`${$t('param_riskrestriction')}`"
    :info="info"
    :options="riskReplicationOptions"
    :show-advanced="showAdvanced"
    v-model="riskReplicationOption"
  >
    <fg-numeric v-model="maxNumberOpenPositions"
                :min="riskReplicationAdvancedOption.MAX_NUMBER_OPEN_POSITIONS.min"
                :max="riskReplicationAdvancedOption.MAX_NUMBER_OPEN_POSITIONS.max"
                :step="riskReplicationAdvancedOption.MAX_NUMBER_OPEN_POSITIONS.step"
                :precision="riskReplicationAdvancedOption.MAX_NUMBER_OPEN_POSITIONS.precision"
                :label="$t('maxnumberopenpositionstxt')"
    ></fg-numeric>
    <fg-numeric v-model="minLotSize"
                :min="riskReplicationAdvancedOption.MIN_LOT_SIZE.min"
                :max="riskReplicationAdvancedOption.MIN_LOT_SIZE.max"
                :step="riskReplicationAdvancedOption.MIN_LOT_SIZE.step"
                :precision="riskReplicationAdvancedOption.MIN_LOT_SIZE.precision"
                :label="$t('minlotsizetxt')"
    ></fg-numeric>
    <fg-numeric v-model="maxLotSize"
                :min="riskReplicationAdvancedOption.MAX_LOT_SIZE.min"
                :max="riskReplicationAdvancedOption.MAX_LOT_SIZE.max"
                :step="riskReplicationAdvancedOption.MAX_LOT_SIZE.step"
                :precision="riskReplicationAdvancedOption.MAX_LOT_SIZE.precision"
                :label="$t('maxlotsizetxt')"
    ></fg-numeric>
    <fg-numeric v-model="maxAccountMarginUsed"
                :min="riskReplicationAdvancedOption.MAX_ACCOUNT_MARGIN.min"
                :max="riskReplicationAdvancedOption.MAX_ACCOUNT_MARGIN.max"
                :step="riskReplicationAdvancedOption.MAX_ACCOUNT_MARGIN.step"
                :precision="riskReplicationAdvancedOption.MAX_ACCOUNT_MARGIN.precision"
                :label="$t('maxaccountmarginusedtxt')"
    ></fg-numeric>
  </strategy-parameter-block>
</template>

<script>
import StrategyParameterBlock from "@/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterBlock";
import {mapActions, mapState} from "vuex";
import {RiskReplicationOptions} from "@/models/parameters/RiskReplication";
export default {
  components: {StrategyParameterBlock},
  computed: {
    ...mapState('strategy_params', ['riskReplication', 'settings']),
    info: {
      get() {
        if(this.showAdvanced) {
          // TODO verificar como mostrar as informações dos campos avançados
          let info = '';
          info += this.$t('param_riskrestriction_maxorder_info') + '\n\n';
          info += this.$t('param_riskrestriction_minlot_info') + '\n\n';
          info += this.$t('param_riskrestriction_maxlot_info') + '\n\n';
          info += this.$t('param_riskrestriction_maxmagin_info');
          return info;
        } else {
          return this.riskReplicationOptions.find(option => this.riskReplication.option === option.value).info;
        }
      }
    },
    riskReplicationAdvancedOption: {
      get() {
        return RiskReplicationOptions.ADVANCED
      }
    },
    riskReplicationOption: {
      set(value) {
        this.setCurrentRiskReplication(value);
      },
      get() {
        return this.riskReplication.option;
      },
    },
    maxNumberOpenPositions: {
      set(value) {
        this.setCurrentSettings({maxNumberOpenPositions: value});
      },
      get() {
        return this.settings.maxNumberOpenPositions;
      },
    },
    minLotSize: {
      set(value) {
        this.setCurrentSettings({minLotSize: value});
      },
      get() {
        return this.settings.minLotSize;
      },
    },
    maxLotSize: {
      set(value) {
        this.setCurrentSettings({maxLotSize: value});
      },
      get() {
        return this.settings.maxLotSize;
      },
    },
    maxAccountMarginUsed: {
      set(value) {
        this.setCurrentSettings({maxAccountMarginUsed: value});
      },
      get() {
        return this.settings.maxAccountMarginUsed;
      },
    },
    showAdvanced: {
      get() {
        return this.riskReplication.option === RiskReplicationOptions.ADVANCED;
      }
    }
  },
  data() {
    return {
      riskReplicationOptions: [
        {
          value: RiskReplicationOptions.DISABLED,
          label: this.$t('param_riskrestriction_disabled'),
          info: this.$t('param_riskrestriction_disabled_info'),
        },
        {
          value: RiskReplicationOptions.ADVANCED,
          label: this.$t('advancedsettings'),
          info: '',
        },
      ],
    }
  },
  methods: {
    ...mapActions('strategy_params', ['setCurrentRiskReplication', 'setCurrentSettings']),
  },
}
</script>

<style scoped>

</style>
