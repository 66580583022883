<template>
  <strategy-parameter-block
    icon="fa fa-signal"
    header-color="hg-info"
    :title="`${$t('param_signals')}`"
    :info="info"
    :options="signalsOptions"
    :show-advanced="showAdvanced"
    v-model="signalOption">

    <label>{{ $t('param_signals_advanced_openclose_label') }}</label>
    <el-select v-model="signalAdvancedOpenCloseOption">
      <el-option v-for="(option, index) in signalAdvancedOpenCloseOptions"
                 :key="index"
                 :value="option.value"
                 :label="option.label"></el-option>
    </el-select>

    <label>{{ $t('param_signals_advanced_assets_label') }}</label>
    <el-select v-model="signalAdvancedAssetsOption">
      <el-option v-for="(option, index) in signalAdvancedAssetsOptions"
                 :key="index"
                 :value="option.value"
                 :label="option.label"
      ></el-option>
    </el-select>

    <fg-input
      v-if="showAssetOptionOnlyValue"
      v-model="assetOptionOnlyValue"
      :label="$t('param_signals_advanced_assets_only')"
      :class="showAssetOptionOnlyValue && !assetOptionOnlyValue ? 'has-danger' : ''"
    >
    </fg-input>

    <fg-input
      v-if="showAssetOptionExceptValue"
      v-model="assetOptionExceptValue"
      :label="$t('param_signals_advanced_assets_except')"
      :class="showAssetOptionExceptValue && !assetOptionExceptValue ? 'has-danger' : ''"
    >
    </fg-input>

    <label>{{ $t('param_signals_advanced_replication_label') }}</label>
    <el-select v-model="signalAdvancedReplicationOption">
      <el-option v-for="(option, index) in signalAdvancedReplicationOptions"
                 :key="index"
                 :value="option.value"
                 :label="option.label"
      ></el-option>
    </el-select>
    <p-checkbox v-model="blockSignalsWithPriceDeviation"><b>{{ $t('blockSignalsWithPriceDeviationTxt') }}?</b></p-checkbox>
    <div :class="blockSignalsWithPriceDeviation ? '' : 'disabled'">
      <fg-numeric v-model="maxPip"
                  :min="maxPipRules.min"
                  :max="maxPipRules.max"
                  :step="maxPipRules.step"
                  :precision="maxPipRules.precision"
                  :disabled="!blockSignalsWithPriceDeviation"
                  :placeholder="$t('maxpiptxt')"
      ></fg-numeric>
    </div>
  </strategy-parameter-block>
</template>

<script>
import StrategyParameterBlock from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterBlock";
import {mapActions, mapGetters, mapState} from "vuex";
import {SignalOptions} from "src/models/parameters/SignalOptions";
import {SyncOptions} from "@/models/parameters/SyncOptions";
export default {
  components: {StrategyParameterBlock},
  computed: {
    ...mapState('strategy_params', ['signal', 'settings']),
    info: {
      get() {
        if (this.showAdvanced) {
          const replicationInfo =  this.blockSignalsWithPriceDeviation ?
            this.$t('param_sync_advanced_maxacceptable_active_info') :
            this.$t('param_sync_advanced_maxacceptable_inactive_info')
          return this.signalAdvancedOpenCloseOptions.find(oc => this.signalAdvancedOpenCloseOption === oc.value).info
            + '\n\n' +
            this.signalAdvancedAssetsOptions.find(asset => this.signalAdvancedAssetsOption === asset.value).info
            + '\n\n' +
            this.signalAdvancedReplicationOptions.find(replication => this.signalAdvancedReplicationOption === replication.value).info
            + '\n\n' +
            replicationInfo;
        } else {
          return this.signalsOptions.find(option => this.signal.option === option.value).info;
        }
      }
    },
    signalOption: {
      set(value) {
        this.setCurrentSignal(value);
      },
      get() {
        return this.signal.option;
      },
    },
    signalAdvancedOpenCloseOption: {
      set(value) {
        this.setCurrentSignalOpenClose(value);
      },
      get() {
        return this.signal.openCloseOption;
      },
    },
    signalAdvancedAssetsOption: {
      set(value) {
        this.setCurrentSignalAsset(value);
      },
      get() {
        return this.signal.assetOption;
      },
    },
    assetOptionOnlyValue: {
      set(value) {
        this.setCurrentAssetOnlyValue(value);
      },
      get() {
        return this.signal.assetOptionOnlyValue;
      },
    },
    showAssetOptionOnlyValue: {
      get() {
        return this.signalAdvancedAssetsOption === SignalOptions.ADVANCED.ASSETS.ONLY;
      },
    },
    assetOptionExceptValue: {
      set(value) {
        this.setCurrentAssetExceptValue(value);
      },
      get() {
        return this.signal.assetOptionExceptValue;
      },
    },
    showAssetOptionExceptValue: {
      get() {
        return this.signalAdvancedAssetsOption === SignalOptions.ADVANCED.ASSETS.EXCEPT;
      },
    },
    signalAdvancedReplicationOption: {
      set(value) {
        this.setCurrentSignalReplication(value);
      },
      get() {
        return this.signal.replicationOption;
      },
    },
    blockSignalsWithPriceDeviation: {
      set(value) {
        this.setCurrentSettings({blockSignalsWithPriceDeviation: value});
        this.setCurrentSettings({maxPip: this.maxPipRules.default});
      },
      get() {
        return this.settings.blockSignalsWithPriceDeviation;
      },
    },
    maxPip: {
      set(value) {
        this.setCurrentSettings({maxPip: value});
      },
      get() {
        return this.settings.maxPip;
      },
    },
    maxPipRules: {
      get() {
        return SyncOptions.ADVANCED.MAX_PIP;
      }
    },
    showAdvanced: {
      get() {
        return this.signal.option === SignalOptions.ADVANCED;
      }
    },
  },
  data() {
    return {
      signalsOptions: [
        {
          value: SignalOptions.REPLICATEALL,
          label: SignalOptions.REPLICATEALL.label(),
          info: SignalOptions.REPLICATEALL.info()
        },
        {
          value: SignalOptions.ADVANCED,
          label: this.$t('advancedsettings'),
          info: '',
        },
      ],
      signalAdvancedOpenCloseOptions: [
        {
          value: SignalOptions.ADVANCED.OPENCLOSE.BOTH,
          label: SignalOptions.ADVANCED.OPENCLOSE.BOTH.label(),
          info: SignalOptions.ADVANCED.OPENCLOSE.BOTH.info(),
        },
        {
          value: SignalOptions.ADVANCED.OPENCLOSE.OPEN,
          label: SignalOptions.ADVANCED.OPENCLOSE.OPEN.label(),
          info: SignalOptions.ADVANCED.OPENCLOSE.OPEN.info(),
        },
        {
          value: SignalOptions.ADVANCED.OPENCLOSE.CLOSE,
          label: SignalOptions.ADVANCED.OPENCLOSE.CLOSE.label(),
          info: SignalOptions.ADVANCED.OPENCLOSE.CLOSE.info(),
        },
        {
          value: SignalOptions.ADVANCED.OPENCLOSE.BLOCKED,
          label: SignalOptions.ADVANCED.OPENCLOSE.BLOCKED.label(),
          info: SignalOptions.ADVANCED.OPENCLOSE.BLOCKED.info(),
        },
      ],
      signalAdvancedAssetsOptions: [
        {
          value: SignalOptions.ADVANCED.ASSETS.ALL,
          label: SignalOptions.ADVANCED.ASSETS.ALL.label(),
          info: SignalOptions.ADVANCED.ASSETS.ALL.info(),
        },
        {
          value: SignalOptions.ADVANCED.ASSETS.ONLY,
          label: SignalOptions.ADVANCED.ASSETS.ONLY.label(),
          info: SignalOptions.ADVANCED.ASSETS.ONLY.info(),
        },
        {
          value: SignalOptions.ADVANCED.ASSETS.EXCEPT,
          label: SignalOptions.ADVANCED.ASSETS.EXCEPT.label(),
          info: SignalOptions.ADVANCED.ASSETS.EXCEPT.info(),
        },
      ],
      signalAdvancedReplicationOptions: [
        {
          value: SignalOptions.ADVANCED.REPLICATION.BOTH,
          label: SignalOptions.ADVANCED.REPLICATION.BOTH.label(),
          info: SignalOptions.ADVANCED.REPLICATION.BOTH.info(),
        },
        {
          value: SignalOptions.ADVANCED.REPLICATION.BUY,
          label: SignalOptions.ADVANCED.REPLICATION.BUY.label(),
          info: SignalOptions.ADVANCED.REPLICATION.BUY.info(),
        },
        {
          value: SignalOptions.ADVANCED.REPLICATION.SELL,
          label: SignalOptions.ADVANCED.REPLICATION.SELL.label(),
          info: SignalOptions.ADVANCED.REPLICATION.SELL.info(),
        },
      ],
    }
  },
  methods: {
    ...mapActions(
      'strategy_params',
      [
        'setCurrentSignal',
        'setCurrentSignalOpenClose',
        'setCurrentSignalAsset',
        'setCurrentSignalReplication',
        'setCurrentAssetOnlyValue',
        'setCurrentAssetExceptValue',
        'setCurrentSettings',
      ]
    ),
  },
}
</script>

<style scoped>

</style>
