<template>
  <modal :show.sync="showConfirmationModal" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg">
    <h4 slot="header" class="title title-up">{{ $t('strategysummary') }} <strong> {{ accountInfo.name }} </strong> </h4>
    <div class="col-md-12 mx-auto">
      <div class="row">
          <div class="col-sm-12 text-center">
            <br>
          </div>
      </div>

      <div>
        <div class="col-md-12">
         <div class="row">
            <div class="col-md-6">
                <h6><i class="fa fa-tasks"></i> {{ txt.allocation }} </h6>
                <p>{{allocationLabel}}</p>
                <p></p>
            </div>
            <div class="col-md-6">
                  <h6> {{ txt.ratiotxt }} </h6>
                  <p v-if="settings.allocationMethod === 'balRatio'">{{ settings.balRatio }}</p>
                  <p v-if="settings.allocationMethod === 'lotMultiplier'">{{ settings.lotMultiplier }}</p>
                  <p v-if="settings.allocationMethod === 'eqRatio'">{{ settings.eqRatio }}</p>
                  <p v-if="settings.allocationMethod === 'lotMoneyRatio'">{{ settings.lotMoneyRatioX }} {{$t('helper_lotmoneyratioy')}}  {{ settings.lotMoneyRatioY }}</p>
                  <p v-if="settings.allocationMethod === 'fixedLots'">{{ settings.fixedLots }}</p>
                  <p></p>
            </div>
          </div>
          <hr>
        </div>
        <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <h6><i class="fa fa-refresh"></i> {{ txt.param_sync }}</h6>
                <p> {{ (settings.copyOpenTrades) ? syncLabel : $t('notxt') }} </p>
                <p></p>
              </div>

              <div class="col-md-6" v-if="settings.copyOpenTrades">
                <h6><i class="fa fa-refresh"></i> {{ txt.copytradessincetxt }}</h6>
                <p> {{ (settings.copyTradesSince) ? settings.copyTradesSince : ((settings.copyOpenTrades) ? $t('alwaystxt') : $t('notavailable')) }} </p>
              </div>
              <div class="col-md-6">

              </div>
              <div class="col-md-6" v-if="settings.blockSignalsWithPriceDeviation">
                <h6><i class="fa fa-refresh"></i> {{ txt.blockSignalsWithPriceDeviationTxt }}</h6>
                <p> {{ (settings.maxPip) ? settings.maxPip : $t('notavailable') }}</p>
              </div>
            </div>
            <hr>
          </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h6><i class="fa fa-signal"></i> {{ txt.assetspairstoreceivesignalstxt }}</h6>
             <p class="mb-0" v-html="label" v-for="label in signalLabel">
                {{label}}
              </p>
            </div>

            <div class="col-md-6">
              <h6><i class="fa fa-signal"></i> {{ txt.receivenewtradesignalstxt }}</h6>
              <p>{{ (settings.receiveNewTradeSignals) ? $t('yestxt') : $t('notxt') }}</p>
            </div>
          </div>
          <hr>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h6><i class="fa fa-flag-checkered"></i> {{ $t('closealltradesatoveralllosstxt') }}</h6>
              <p>{{ (settings.closeAllTradesAtOverallLoss) ? $t('yestxt') : $t('notxt') }}</p>
            </div>

            <div class="col-md-6">

              <p></p>
            </div>

            <div class="col-md-6">
              <h6><i class="fa fa-flag-checkered"></i> {{ $t('maxnumberopenpositionstxt') }}</h6>
              {{settings.maxNumberOpenPositions}}
              <p></p>
            </div>

            <div class="col-md-6">
              <h6><i class="fa fa-flag-checkered"></i> {{ $t('minlotsizetxt') }}</h6>
              {{settings.minLotSize}}
              <p></p>
            </div>
            <div class="col-md-6">
              <h6><i class="fa fa-flag-checkered"></i> {{ $t('maxlotsizetxt') }}</h6>
               {{settings.maxLotSize}}
               <p></p>
            </div>
            <div class="col-md-6">
              <h6><i class="fa fa-flag-checkered"></i> {{ $t('maxaccountmarginusedtxt') }}</h6>
               {{settings.maxAccountMarginUsed}}
              <p></p>
            </div>
          </div>
          <hr>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h6><i class="fa fa-flag"></i> {{ txt.nonewtradesdrawdownabovetxt }}</h6>
              <p>{{ settings.noNewTradesDrawdownAbove }}</p>
              <p></p>
            </div>

            <div class="col-md-6">
              <div v-if="settings.closeAllTradesAtOverallLossChoice == 'lossPercentToCloseProfit'">
                <h6><i class="fa fa-flag"></i> {{ $t('losspercenttocloseprofittxt') }}</h6>
                <p>{{
                    (settings.lossPercentToCloseProfit) ? settings.lossPercentToCloseProfit + '%' : $t('notavailable')
                  }}</p>
              </div>
              <div v-if="settings.closeAllTradesAtOverallLossChoice == 'lossMoneyToCloseProfit'">
                <h6><i class="fa fa-flag"></i> {{ $t('lossmoneytocloseprofittxt') }}</h6>
                <p>{{ (settings.lossMoneyToCloseProfit) ? settings.lossMoneyToCloseProfit : $t('notavailable') }}</p>
              </div>
            </div>
          </div>
          <hr>
        </div>
        <br>
          <div class="col-sm-12 text-center" style="margin: 10px">
            <p class="lead">
              <p-checkbox v-model="agree" :disabled="submitting" class="label-check">{{ $t('pleaseconfirm') }}</p-checkbox>
            </p>
          </div>
      </div>
    </div>
    <template slot="footer" v-if="submitting">
      <p-button type="info" link>
        <i class="fa fa-cog fa-spin fa-fw" style="font-size: 18px"></i>
      </p-button>
    </template>
    <template slot="footer" v-if="!submitting">
      <div class="left-side">
        <p-button type="success" link :disabled="!agree" @click="updateStrategyParams"> {{ $t('savechanges') }}</p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button type="danger" link @click="closeConfirmationModal">{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>

import Modal from 'src/components/UIComponents/Modal';
import {mapActions, mapState} from "vuex";
import Constants from "src/assets/constants";
import swal from "sweetalert2";
import {AllocationOptions} from "src/models/parameters/AllocationOptions";
import {SignalOptions} from "src/models/parameters/SignalOptions";

const rules = Constants.methods.getRules()

export default {
  name: "StrategyParameterConfirmation.vue",
  components: {Modal},
  computed: {
    ...mapState('strategy_params', ['showConfirmationModal','settings','accountInfo','accId','stratId','adminFields','allocation','sync','signal']),
    allocationLabel: {
      get() {
        return this.allocation.method === AllocationOptions.ADVANCED ? this.allocation.advancedMethod.label() : this.allocation.method.label()
      }
    },
    syncLabel: {
      get() {
        return this.sync.option.label()
      }
    },
    signalLabel: {
      get() {
        return this.signal.option === SignalOptions.ADVANCED ?
          [
            this.signal.openCloseOption.label(),
            this.signal.assetOption.label() + this.assetPair,
            this.signal.replicationOption.label()
          ] :
          [
            this.signal.option.label()
          ]
      }
    },
    assetPair: {
      get() {
        if(this.settings.allButTheseAssetsPairsReceiveSignal) {
          return " <b>("+ this.settings.allButTheseAssetsPairsReceiveSignal+")</b>"
        }
        if(this.settings.onlyTheseAssetsPairsReceiveSignal) {
          return " <b>("+ this.settings.onlyTheseAssetsPairsReceiveSignal+")</b>"
        }
        return ""
      }
    }
  },
  data() {
    return {
      txt: {
        allocation: this.$t('allocation'),
        copyOpenTradesTxt: this.$t('copyOpenTradesTxt'),
        assetspairstoreceivesignalstxt: this.$t('assetspairstoreceivesignalstxt'),
        closealltradesatoveralllosstxt: this.$t('closealltradesatoveralllosstxt'),
        nonewtradesdrawdownabovetxt: this.$t('nonewtradesdrawdownabovetxt'),
        param_allocation_advanced_fixed: this.$t('param_allocation_advanced_fixed'),
        copytradessincetxt: this.$t('copytradessincetxt'),
        lossmoneytocloseprofittxt: this.$t('lossmoneytocloseprofittxt'),
        receivenewtradesignalstxt: this.$t('receivenewtradesignalstxt'),
        ratiotxt: this.$t('ratiotxt'),
        param_sync: this.$t('param_sync'),
        blockSignalsWithPriceDeviationTxt: this.$t('blockSignalsWithPriceDeviationTxt'),
      },
      submitting: false,
      agree: false,
      isAdmin: rules.isAdmin
    }
  },
  methods: {
    ...mapActions('strategy_params', ['closeConfirmationModal']),
    updateStrategyParams() {
      this.submitting = true;
      const upsertDone = resp => {
        if (this.isAdmin) {
          this.$updateAssetFollower({
              sid: this.stratId,
              aid: this.accId,
              tradedAssets: this.adminFields.tradedAssetsMatching,
              suffix: this.adminFields.assetSuffix,
              prefix: this.adminFields.assetPrefix,
              tradeComment: this.adminFields.tradeComment,
              defaultSuffix: this.adminFields.defaultSuffix,
          }).then(resp2 => this.successDone(resp.msg), failOp)
        } else {
          this.successDone(resp.msg)
        }
      }
      const failOp = resp => {
        this.submitting = false;
        swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
          .then(this.closeConfirmationModal)
      }
      this.$upsertStrategy(
        this.accountInfo.userid,
        this.settings,
        this.stratId,
        this.accId,
      ).then(upsertDone, failOp)
    },
    successDone(msg) {
      this.submitting = false;
      this.closeConfirmationModal();
      swal(this.$t('successtxt'), this.$t(msg), "success").then(() => {
        if (this.isAdmin) {
          this.$router.push('/admin_accounts/'+this.accountInfo.userid);
        } else {
          this.$router.push('/accounts/main');
        }
      });
    },
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
