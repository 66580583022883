<template>
  <strategy-parameter-block
    icon="fa fa-refresh"
    header-color="hg-primary"
    :title="`${$t('param_sync')}`"
    :info="info"
    :options="syncOptions"
    :show-advanced="showAdvanced"
    v-model="syncOption"
  >
    <fieldset>
      <p-checkbox v-model="copyOpenTrades" class="mt1"><b>{{ $t('param_sync_advanced_since') }}</b>?</p-checkbox>
      <fg-input>
        <date-picker
          :disabled="!copyOpenTrades"
          :placeholder="$t('copytradessincetxt')"
          v-model="copyTradesSince"
          dateFormat="YYYY.MM.DD HH:mm"
        ></date-picker>
      </fg-input>
      <p-checkbox v-model="blockSignalsWithPriceDeviation"><b>{{ $t('blockSignalsWithPriceDeviationTxt') }}?</b></p-checkbox>
      <div :class="blockSignalsWithPriceDeviation ? '' : 'disabled'">
        <fg-numeric v-model="maxPip"
                    :min="maxPipRules.min"
                    :max="maxPipRules.max"
                    :step="maxPipRules.step"
                    :precision="maxPipRules.precision"
                    :disabled="!blockSignalsWithPriceDeviation"
                    :placeholder="$t('maxpiptxt')"
        ></fg-numeric>
      </div>
    </fieldset>
  </strategy-parameter-block>
</template>

<script>
import StrategyParameterBlock from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterBlock";
import DatePicker from "src/components/UIComponents/Inputs/DatePicker";
import {mapActions, mapGetters, mapState} from "vuex";
import {SyncOptions} from "src/models/parameters/SyncOptions";
import {getDefaultPassedDate} from "src/util/DateUtils";

export default {
  components: {DatePicker, StrategyParameterBlock},
  computed: {
    ...mapState('strategy_params', ['sync', 'settings', 'accountInfo']),
    info: {
      get() {
        if(this.showAdvanced) {
          let info = '';
          info += this.copyOpenTrades ?
            this.$t('param_sync_advanced_since_active_info') :
            this.$t('param_sync_advanced_since_inactive_info');
          info += this.blockSignalsWithPriceDeviation ?
            this.$t('param_sync_advanced_maxacceptable_active_info') :
            this.$t('param_sync_advanced_maxacceptable_inactive_info');
          return info;
        } else {
          return this.syncOptions.find(option => this.sync.option === option.value).info;
        }
      }
    },
    syncOption: {
      set(value) {
        this.setCurrentSync(value);
      },
      get() {
        return this.sync.option;
      },
    },
    copyOpenTrades: {
      set(value) {
        const newValue = {copyOpenTrades: value};
        if(!value) {
          newValue['copyTradesSince'] = '';
        }
        this.setCurrentSettings(newValue);
      },
      get() {
        return this.settings.copyOpenTrades;
      },
    },
    copyTradesSince: {
      set(value) {
        this.setCurrentSettings({copyTradesSince: this.copyOpenTrades ? value : ''});
      },
      get() {
        if(!this.settings.copyTradesSince) {
          this.copyTradesSince = getDefaultPassedDate();
        }
        return this.settings.copyTradesSince;
      },
    },
    blockSignalsWithPriceDeviation: {
      set(value) {
        this.setCurrentSettings({blockSignalsWithPriceDeviation: value});
        this.setCurrentSettings({maxPip: this.maxPipRules.default});
      },
      get() {
        return this.settings.blockSignalsWithPriceDeviation;
      },
    },
    maxPip: {
      set(value) {
        this.setCurrentSettings({maxPip: value});
      },
      get() {
        return this.settings.maxPip;
      },
    },
    maxPipRules: {
      get() {
        return SyncOptions.ADVANCED.MAX_PIP;
      }
    },
    showAdvanced: {
      get() {
        return this.sync.option === SyncOptions.ADVANCED;
      }
    },
  },
  data() {
    return {
      syncOptions: [
        {
          value: SyncOptions.ALL,
          label: SyncOptions.ALL.label(),
          info: SyncOptions.ALL.info(),
        },
        {
          value: SyncOptions.NONE,
          label: SyncOptions.NONE.label(),
          info: SyncOptions.NONE.info(),
        },
        {
          value: SyncOptions.BESTPRICE,
          label: SyncOptions.BESTPRICE.label(),
          info: SyncOptions.BESTPRICE.info(),
        },
        {
          value: SyncOptions.NONEOLD,
          label: SyncOptions.NONEOLD.label(),
          info: SyncOptions.NONEOLD.info(),
        },
        {
          value: SyncOptions.ADVANCED,
          label: this.$t('advancedsettings'),
          info: '',
        },
      ],
    }
  },
  methods: {
    ...mapActions('strategy_params', ['setCurrentSync', 'setCurrentSettings']),
  },
}
</script>

<style scoped lang="scss">

</style>
