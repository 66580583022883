<template>
  <strategy-parameter-block
    icon="fa fa-flag"
    header-color="hg-danger"
    :title="`${$t('param_maxloss')}`"
    :info="info"
    :options="riskMaxLossOptions"
    :show-advanced="showAdvanced"
    v-model="riskMaxLossOption"
  >
    <fg-numeric v-model="noNewTradesDrawdownAbove"
                :min="riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.min"
                :max="riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.max"
                :step="riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.step"
                :precision="riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.precision"
                :label="$t('param_maxloss_advanced_neworderlimit')"
    ></fg-numeric>
    <el-select v-model="advancedOption">
      <el-option v-for="(option, index) in advancedOptions"
                 :key="index"
                 :value="option.value"
                 :label="option.label"
      ></el-option>
    </el-select>
    <fg-numeric v-model="advancedOptionValue"
                :min="advancedOption.min"
                :max="advancedOption.max"
                :step="advancedOption.step"
                :precision="advancedOption.precision"
                :label="$t('value')"
    ></fg-numeric>
  </strategy-parameter-block>
</template>

<script>
import StrategyParameterBlock from "@/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterBlock";
import {mapActions, mapState} from "vuex";
import {RiskMaxLossOptions} from "@/models/parameters/RiskMaxLoss";
export default {
  components: {StrategyParameterBlock},
  computed: {
    ...mapState('strategy_params', ['riskMaxLoss', 'settings', 'accountInfo']),
    info: {
      get() {
        if (this.showAdvanced) {
          return this.$t('param_maxloss_advanced_neworderlimit_info') + '\n\n' +
            this.advancedOptions.find(option => {
            return this.advancedOption.value.closeAllTradesAtOverallLossChoice ===
            option.value.value.closeAllTradesAtOverallLossChoice
          }).info + '\n'
        } else {
          return this.riskMaxLossOptions.find(option => this.riskMaxLoss.option === option.value).info;
        }
      }
    },
    riskMaxLossOption: {
      set(value) {
        this.setCurrentRiskMaxLoss(value);
      },
      get() {
        return this.riskMaxLoss.option;
      },
    },
    advancedOption: {
      set(value) {
        this.setCurrentRiskMaxLossAdvanced(value);
        this.advancedOptionValue = this.advancedOption.default;
      },
      get() {
        if (this.riskMaxLoss.advancedOption === RiskMaxLossOptions.ADVANCED.VALUE) {
          const balance = parseFloat(this.accountInfo.balance).toFixed(2)
          return Object.assign(
            {},
            RiskMaxLossOptions.ADVANCED.VALUE,
            {
              default: balance,
              max: parseFloat(balance),
            }
          )
        }
        return this.riskMaxLoss.advancedOption;
      },
    },
    advancedOptionValue: {
      set(value) {
        this.setCurrentRiskMaxLossAdvancedValue(value);
      },
      get() {
        return this.riskMaxLoss.value;
      },
    },
    riskMaxLossAdvanced: {
      get() {
        return RiskMaxLossOptions.ADVANCED
      }
    },
    noNewTradesDrawdownAbove: {
      set(value) {
        this.setCurrentSettings({noNewTradesDrawdownAbove: value});
      },
      get() {
        return this.settings.noNewTradesDrawdownAbove;
      },
    },
    showAdvanced: {
      get() {
        return this.riskMaxLoss.option === RiskMaxLossOptions.ADVANCED;
      }
    }
  },
  data() {
    return {
      riskMaxLossOptions: [
        {
          value: RiskMaxLossOptions.NONELOSS,
          label: this.$t('param_maxloss_none'),
          info: this.$t('param_maxloss_none_info'),
        },
        {
          value: RiskMaxLossOptions.HALFLOSS,
          label: this.$t('param_maxloss_50'),
          info: this.$t('param_maxloss_50_info'),
        },
        {
          value: RiskMaxLossOptions.QUARTERLOSS,
          label: this.$t('param_maxloss_25'),
          info: this.$t('param_maxloss_25_info'),
        },
        {
          value:  RiskMaxLossOptions.ADVANCED,
          label: this.$t('advancedsettings'),
          info: ''
        },
      ],
      advancedOptions: [
        {
          value: RiskMaxLossOptions.ADVANCED.PERCENT,
          label: this.$t('param_maxloss_advanced_autoclosepercent'),
          info: this.$t('param_maxloss_advanced_autoclosepercent_info'),
        },
        {
          value: RiskMaxLossOptions.ADVANCED.VALUE,
          label: this.$t('param_maxloss_advanced_autoclosevalue'),
          info: this.$t('param_maxloss_advanced_autoclosevalue_info'),
        },
      ],
    }
  },
  methods: {
    ...mapActions(
      'strategy_params',
      [
        'setCurrentRiskMaxLoss',
        'setCurrentRiskMaxLossAdvanced',
        'setCurrentRiskMaxLossAdvancedValue',
        'setCurrentSettings'
      ]
    ),
    validateAdvancedOptionValue() {
      if ( this.advancedOption.value.closeAllTradesAtOverallLossChoice === 'lossPercentToCloseProfit' ) {
        if ( this.advancedOptionValue < parseFloat(this.riskMaxLossAdvanced.PERCENT.min) ) {
          this.advancedOptionValue = this.riskMaxLossAdvanced.PERCENT.min;
        }
        if (this.advancedOptionValue > parseFloat(this.riskMaxLossAdvanced.PERCENT.max) ) {
          this.advancedOptionValue = this.riskMaxLossAdvanced.PERCENT.max;
        }
      }
      if ( this.advancedOption.value.closeAllTradesAtOverallLossChoice === 'lossMoneyToCloseProfit' ) {
        if ( this.advancedOptionValue < parseFloat(this.riskMaxLossAdvanced.VALUE.min) ) {
          this.advancedOptionValue = this.riskMaxLossAdvanced.VALUE.min;
        }
      }
    },
    validateNoNewTradesDrawdownAbove() {
      if ( this.noNewTradesDrawdownAbove < parseInt(this.riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.min) ) {
        this.noNewTradesDrawdownAbove = this.riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.min
      }
      if ( this.noNewTradesDrawdownAbove > parseInt(this.riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.max) ) {
        this.noNewTradesDrawdownAbove = this.riskMaxLossAdvanced.TRADES_DRAWDOWN_ABOVE.max
      }
    },
  },
}
</script>

<style scoped>

</style>
