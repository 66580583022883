import { render, staticRenderFns } from "./StrategyRiskMaxLoss.vue?vue&type=template&id=03e7c2d7&scoped=true&"
import script from "./StrategyRiskMaxLoss.vue?vue&type=script&lang=js&"
export * from "./StrategyRiskMaxLoss.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e7c2d7",
  null
  
)

export default component.exports