<template>
  <div class="strategy-param-block">
    <el-card :class="headerColor">
      <span slot="header" class="text-white" style="font-size: 20px">
        <i v-if="icon" :class="icon"></i>
        {{ title }}
      </span>
      <div class="row" style="margin-bottom: 15px">
        <div class="form-group col-md-4">
          <el-select v-on="$listeners" v-bind="$attrs">
            <el-option
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
              :label="option.label">
            </el-option>
          </el-select>
          <div v-if="showAdvanced">
            <slot></slot>
          </div>
        </div>
        <div class="col-md-8 right-block">
          <pre>{{info}}</pre>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import {Alert, Card} from "element-ui";

Vue.use(Alert);
Vue.use(Card);

export default {
  props: {
    icon: {type: String, default: ''},
    title: {type: String, default: ''},
    headerColor: {type: String, default: ''},
    options: {type: Array, default: () => []},
    info: {type: String, default: ''},
    showAdvanced: {type: Boolean, default: false}
  },
}
</script>

<style lang="scss">
@import '../../../../../../assets/sass/paper/variables';

.strategy-param-block {
  label {
    margin-top: .5rem;
    margin-left: .25rem;
  }
  .el-card {
    margin: $margin-bottom;

    .el-input__inner {
      font-weight: bolder !important;
      text-transform: uppercase;
    }

    .right-block {
      //border-left: dashed 1px rgba(102, 97, 91, 0.3);
    }

    pre {
      color: inherit;
      white-space: pre-wrap;
      font-family: 'sans-serif' !important;
      margin: 0 0 10px;
      padding: 10px;
      border: dashed 1px rgba(102, 97, 91, 0.3);
    }

    .el-card__header {
      background-color: $bg-info;
    }
  }
  .el-card__header {
    padding: $padding-small-vertical $padding-btn-horizontal;
    color: $dark-gray;
    font-weight: bold;
    font-size: $font-size-h6;
    i {
      margin-right: $margin-base-vertical;
    }
  }
  .el-card__body {
    padding-bottom: 0;
  }
  .disabled {
    input, .input-group-prepend {
      color: #ccc !important;
      background-color: #f3f3f3 !important;
      border-color: #ccc !important;
    }
  }
}
</style>
