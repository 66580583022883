<template>
  <module
      ref="module"
      id="strategyParameters"
      :use-default-list="false"
    >
    <div slot="global" v-if="inProgress" class="text-center">
      <h2>{{ $t('loadingstratdeets') }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
    </div>
    <template v-else style="margin-top: -1rem;">
      <div slot="title">
        <div class="row">
          <div class="col-md-2">
            <div class="broker-logo logo-size-3">
              <img :src="getLogo(getAccountInfo.broker_logo)" :alt="getAccountInfo.broker_name">
            </div>
          </div>
          <div class="col-md-10 mb-1" style="margin-left: -1rem;">
            <div class="row" style="border-bottom: solid 1px #dbdbdb">
              <h4 style="padding: 0; margin-bottom: 5px; margin-top: 15px">
                <b class="text-info" style="font-size: 32px">{{ getAccountInfo.account_number }}</b>
                -
                <b class="">{{ getAccountInfo.strat_name }}</b>
              </h4>
            </div>
            <div class="row" style="margin-top: 5px; font-size: 16px">
              <span style="margin-right: 30px;">
                <b>{{ $t('balance') }} ({{ getAccountInfo.account_currency }}):</b>
                <b class="text-success" style="font-weight: bolder">
                    {{ parseFloat(getAccountInfo.balance).toFixed(2) }}
                </b>
              </span>
              <span>
                <b>{{ $t('equity') }} ({{ getAccountInfo.account_currency }}):</b>
                <b :class="getAccountInfo.equity < getAccountInfo.balance ? 'text-danger' : 'text-success'" style="font-weight: bolder">
                  {{ parseFloat(getAccountInfo.equity).toFixed(2) }}
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <strategy-parameter-toolbar slot="toolbar-global"></strategy-parameter-toolbar>
      <div slot="global">
        <el-container>
          <el-main>
            <strategy-parameter-confirmation></strategy-parameter-confirmation>
            <strategy-parameter-allocation></strategy-parameter-allocation>
            <strategy-parameter-signal></strategy-parameter-signal>
            <strategy-parameter-risk-replication></strategy-parameter-risk-replication>
            <strategy-risk-max-loss></strategy-risk-max-loss>
          </el-main>
          <el-aside class="ml-3 pl-3 pt-3" :width="expanded">
            <strategy-parameter-admin v-if="expanded == '350px'"></strategy-parameter-admin>
          </el-aside>
        </el-container>
      </div>
    </template>
  </module>
</template>

<script>
import Vue from "vue";
import Constants from "@/assets/constants";
import StrategyParameterAllocation from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterAllocation";
import StrategyParameterSync from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterSync";
import StrategyParameterSignal from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterSignal";
import StrategyParameterRiskReplication from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterRiskReplication";
import StrategyRiskMaxLoss from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyRiskMaxLoss";
import StrategyParameterToolbar from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterToolbar";
import StrategyParameterAdmin from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterAdmin";
import StrategyParameterConfirmation from "src/components/Dashboard/Views/Dashboard/Strategies/Parameters/StrategyParameterConfirmation";
import {Container, Main, Aside} from 'element-ui';
import {mapActions, mapGetters, mapState} from "vuex";

const rules = Constants.methods.getRules();

Vue.use(Aside);
Vue.use(Container);
Vue.use(Main);

export default {
  components: {
    StrategyParameterToolbar,
    StrategyRiskMaxLoss,
    StrategyParameterRiskReplication,
    StrategyParameterSignal,
    StrategyParameterSync,
    StrategyParameterAllocation,
    StrategyParameterAdmin,
    StrategyParameterConfirmation
  },
  computed: {
    ...mapState('strategy_params', ['inProgress', 'expanded', 'accId', 'stratId']),
    ...mapGetters('strategy_params', ['getAccountInfo']),
  },
  data() {
    return {
      disabled: false
    }
  },
  methods: {
    ...mapActions('strategy_params', ['refreshInfo', 'refreshViewState']),
    getLogo(logo) {
      if (!logo) {
        return "";
      }
      return "https://hoko.ams3.digitaloceanspaces.com/brokerlogos/" + logo + ".png"
    },
    returnPage() {
      window.history.back();
    },
  },
  beforeCreate() {
    const params = {
      strat_id: this.$route.params.stratid,
      acc_id: this.$route.params.accid,
    }
    this.$getAccountsParams_v4(params)
    .then(response => {
      if(!response.success) {
        this.$toast.warning(response.msg)
        this.refreshInfo(Object.assign({}, undefined, params));
        this.$toast.warning(this.$t(response.msg || 'unkownerror'));
        if(rules.isAdmin) {
          this.$router.push('/admin_accounts/all');
        } else {
          this.$router.push('/accounts/main');
        }
      }
      this.refreshInfo(Object.assign({}, response.data, params));
    })
    .catch(error => {
      this.$toast.error(this.$t('unkownerror'));
      console.log(error);
    });
  },
}
</script>

<style scoped lang="scss">
@import '../../../../../../assets/sass/paper/variables';
.card-header {
  i {
    margin-right: $margin-base-horizontal;
  }
}
.card.card-body {
  padding: 0;
}
</style>
