<template>
  <div>
    <h6>{{ helper_tradedassetsmatching }}</h6>
    <fg-input v-model="tradedAssetsMatching"
              addon-left-icon="fa fa-keyboard-o"
              type="text"
              :placeholder="helper_tradedassetsmatching"></fg-input>
    <h6>{{ helper_tradecomment }}</h6>
    <fg-input v-model="tradeComment"
              addon-left-icon="fa fa-keyboard-o"
              type="text"
              :placeholder="helper_tradecomment">
    </fg-input>
    <h6>{{ helper_assetprefix }}</h6>
    <fg-input
      v-model="prefix"
      addon-left-icon="fa fa-keyboard-o"
      type="text"
      :placeholder="helper_assetprefix"></fg-input>
    <h6>{{ helper_assetsuffix }}</h6>
    <fg-input
      v-model="suffix"
      addon-left-icon="fa fa-keyboard-o"
      type="text"
      :placeholder="helper_assetsuffix"
      :disabled="isSufixDisabled"
    ></fg-input>

    <p-checkbox
      v-model="defaultSuffix"
      v-on:input="suffix = ''"
      style="font-size: 18px;">
                <span style="color: black;">
                  {{ $t('usedefaultassetssuffix') }}
                </span>
    </p-checkbox>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "StrategyParameterAdmin.vue",
  computed: {
    ...mapState('strategy_params', ['adminFields']),
    tradedAssetsMatching: {
      set(value) {
        this.setAdminFields({tradedAssetsMatching: value})
      },
      get() {
        return this.adminFields.tradedAssetsMatching
      }
    },
    tradeComment: {
      set(value) {
        this.setAdminFields({tradeComment: value})
      },
      get() {
        return this.adminFields.tradeComment
      }
    },
    prefix: {
      set(value) {
        this.setAdminFields({assetPrefix: value})
      },
      get() {
        return this.adminFields.assetPrefix
      }
    },
    suffix: {
      set(value) {
        this.setAdminFields({assetSuffix: value})
      },
      get() {
        return this.adminFields.assetSuffix
      }
    },
    isSufixDisabled: {
      get() {
        return this.adminFields.defaultSuffix
      }
    },
    defaultSuffix: {
      set(value) {
        this.setAdminFields({defaultSuffix: value})
      },
      get() {
        return this.adminFields.defaultSuffix
      }
    }
  },
  data(){
    return {
      helper_tradedassetsmatching: this.$t('helper_tradedassetsmatching'),
      helper_tradecomment: this.$t('helper_tradecomment'),
      helper_assetprefix: this.$t('helper_assetprefix'),
      helper_assetsuffix: this.$t('helper_assetsuffix'),
      helper_stratname: this.$t('helper_stratname'),
    }
  },
  methods: {
    ...mapActions('strategy_params', ['setAdminFields'])
  }
}
</script>

<style scoped>

</style>
